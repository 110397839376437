<template>
  <v-card class="mx-auto px-0 shadow-small" :color="item.color" :class="item.status == 0 ? 'disabled':''">
    <router-link class="bold white--text enlace-off mx-auto mt-2"
      :to="ruta">
      <v-img
        :src="URL_IMG+item.img"
        class="white--text align-end img-box-curso"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        :height="max"
      >
        <v-card-title v-text="item.name "></v-card-title>
        <p v-if="item.status == 0" class="text-center ">Curso pendiente por realizar pago</p>
        <span class="area">{{ item.area.description }}</span>
      </v-img>
    </router-link>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'boxCurso',
  props:{
    item:{
      type: Object
    },
    user:{
      type: Boolean,
      default: false
    },
    max:{
      type: String,
      default: '200'
    }
  },
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState(['URL_IMG']),
    ruta(){
      if (this.item.status == 0) {
        return '#'
      }
      if (this.$router.currentRoute.name == 'userwelcome') {
        return { name: 'usercourse', params: { area: this.item.area.slug, area_id: this.item.areas_id, slug: this.item.slug }}
      }
      return { name: 'course', params: { area: this.item.area.slug, area_id: this.item.areas_id, slug: this.item.slug }}
    }
  },
  mounted(){
  },
  methods:{
    
  }
}
</script>

<style lang="scss">
  .v-card.disabled{
    opacity: .3;
    a{
      text-decoration: none;
    }
  }
  .img-box-curso{
    position: relative;
  }
  .area{
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(94, 189, 153);
    padding: 5px;
    border-radius: 3px;
  }
</style>
