<template>
  <section id="formulario_contacto">
    <v-col cols="12">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-row>
          <v-col cols="12" :sm="widthsm" :md="widthsm">
            <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="name"
                name="name"
                label="Nombre y Apellido"
                filled
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12"  :sm="widthsm" :md="widthsm">
            <ValidationProvider name="correo" rules="email|required" v-slot="{ errors }">
              <v-text-field
                v-model="email"
                name="email"
                label="Correo"
                filled
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" :sm="widthsm" :md="widthsm" v-if="isCurso">
            <ValidationProvider name="curso" rules="required" v-slot="{ errors }">
              <v-select
                :items="areas"
                item-text="description"
                item-value="id"
                v-model="area"
                filled
                label="Modalidad"
                :error-messages="errors"
              ></v-select>

            </ValidationProvider>
          </v-col>
            
          <v-col cols="12" :sm="widthsm" :md="widthsm" v-if="isCurso">
            <ValidationProvider name="curso" :rules="requiredCurso" v-slot="{ errors }">
              <v-select
                  :items="cursos_activos"
                  item-text="name"
                  item-value="id"
                  v-model="curso"
                  filled
                  label="Curso"
                  :error-messages="errors"
                  :disabled="true"
                ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" :sm="widthsm" :md="widthsm">
            <ValidationProvider name="teléfono" rules="required|numeric" v-slot="{ errors }">
              <v-text-field
                v-model="phone"
                name="phone"
                label="Telefono"
                filled
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="mensaje" rules="required|min:10" v-slot="{ errors }">
              <v-textarea
                v-model="message"
                name="message"
                label="Mensaje"
                filled
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" v-if="isFile">
            <ValidationProvider name="documento" rules="required" v-slot="{ errors }">
              <v-file-input
                truncate-length="15"
                label="Documento"
                v-model="documento"
                :error-messages="errors"
              ></v-file-input>
            </ValidationProvider>
          </v-col>
          
        </v-row>
        <p class="contact-text font-weight-bold text-center grey--text text--darken-1">
          <v-spacer class="mb-12"></v-spacer>
          <v-btn
            color="#5ebd99"
            rounded
            class="elevation-0 text-center px-8"
            dark
            :loading="loading"
            @click="passes(sendForm)"
            >Enviar</v-btn>
        </p>
      </ValidationObserver>
    </v-col>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from 'vuex'

export default {
  name: 'formulario-contacto',
  props:{
    curso:{
      type: [String,Number]
    },
    isCurso:{
      type: Boolean,
      default: true
    },
    widthsm:{
      type: Number,
      default: 6
    },
    type:{
      type: String,
      default: 'curso'
    },
    descuento:{
      type: String,
      default: null
    },
    isFile:{
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data(){
    return{
      name: null,
      email: null,
      phone: null,
      message: null,
      loading: false,
      documento: null,
      area: null, 

    }
  },
  watch:{
    curso: 'defineDatosForm'
  },
  computed:{
    ...mapState('home',['cursos','areas','cursos_activos']),
    requiredCurso(){
      if (this.isCurso) {
        return 'required'
      }
      return ''
    },
  },
  mounted(){

  },
  methods:{
    defineDatosForm(){
      if (this.curso) {
        console.log(this.curso)
        let curso = this.cursos_activos.find((element)=>element.id == this.curso)
         console.log(curso)
        this.area = curso.area.id
      }
    },
    async sendForm(){
      
      var formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone",  this.phone);
      formData.append("message", this.message);
      formData.append("type", this.type);
      formData.append("area", this.area);

      if (this.isFile) {
        formData.append("documento", this.documento);
      }
      
      if (this.type == 'curso') {
        formData.append("curso", this.curso);
      }
      if (this.type == 'descuento') {
        formData.append("descuento", this.descuento);
      }
      this.loading = true
      try{
        const response = await this.$store.dispatch('contact/REGISTER', formData)
        if (response.status) {
          this.$gtag.event('Contactar', {'event_category': 'Contactar','event_action': 'Envio', 'event_label': 'General', 'value': '100'})
          Swal.fire({
            title: 'Mensaje Enviado',
            text: response.mensaje,
            icon: 'success',
            confirmButtonText: 'Ok!'
          })
          this.loading = false 
          if (this.type == 'descuento') {
            this.$emit('retorno')
          }
          
          this.clearData()
        }else{
          Swal.fire({
            title: 'Espera!',
            text: response.mensaje,
            icon: 'error',
            confirmButtonText: 'Ok!'
          })
        }
        
      }catch (e) {

        this.loading = false
      }
    },
    clearData(){
      this.name = null
      this.email = null
      this.phone = null
      this.message = null
    }
  }
}
</script>

<style lang="scss">

</style>
