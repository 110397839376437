var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"formulario_contacto"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":_vm.widthsm,"md":_vm.widthsm}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":"Nombre y Apellido","filled":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.widthsm,"md":_vm.widthsm}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","label":"Correo","filled":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),(_vm.isCurso)?_c('v-col',{attrs:{"cols":"12","sm":_vm.widthsm,"md":_vm.widthsm}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"description","item-value":"id","filled":"","label":"Modalidad","error-messages":errors},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})]}}],null,true)})],1):_vm._e(),(_vm.isCurso)?_c('v-col',{attrs:{"cols":"12","sm":_vm.widthsm,"md":_vm.widthsm}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":_vm.requiredCurso},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.cursos_activos,"item-text":"name","item-value":"id","filled":"","label":"Curso","error-messages":errors,"disabled":true},model:{value:(_vm.curso),callback:function ($$v) {_vm.curso=$$v},expression:"curso"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":_vm.widthsm,"md":_vm.widthsm}},[_c('ValidationProvider',{attrs:{"name":"teléfono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"phone","label":"Telefono","filled":"","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"mensaje","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"message","label":"Mensaje","filled":"","error-messages":errors},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1),(_vm.isFile)?_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"truncate-length":"15","label":"Documento","error-messages":errors},model:{value:(_vm.documento),callback:function ($$v) {_vm.documento=$$v},expression:"documento"}})]}}],null,true)})],1):_vm._e()],1),_c('p',{staticClass:"contact-text font-weight-bold text-center grey--text text--darken-1"},[_c('v-spacer',{staticClass:"mb-12"}),_c('v-btn',{staticClass:"elevation-0 text-center px-8",attrs:{"color":"#5ebd99","rounded":"","dark":"","loading":_vm.loading},on:{"click":function($event){return passes(_vm.sendForm)}}},[_vm._v("Enviar")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }